<template>
	<div class="saving-screen" v-if="isSaving">
		<span>Kaydediliyor, lütfen bekleyin....</span>
	</div>
	<div class="saving-screen" v-if="isLoading">
		<span>Yükleniyor, lütfen bekleyin....</span>
	</div>
	<r-flex class="r-edit-img-button-C">
		<r-btn @click="cancelF" size="medium" style="width: 100%">Vazgeç</r-btn>
		<r-btn
			style="width: 100%"
			@click="undoF"
			:disabled="aRects.length === 0 || isSaving"
			size="medium"
			bgcolor="#b17200"
		>
			Geri Al
		</r-btn>
		<r-btn
			style="width: 100%"
			@click="clearF"
			:disabled="aRects.length === 0 || isSaving"
			size="medium"
			bgcolor="#bb0202"
		>
			Tümünü Sil
		</r-btn>
		<r-btn
			style="width: 100%"
			@click="saveImages"
			:disabled="aRects.length === 0 || isSaving"
			size="medium"
			bgcolor="#009bc1"
			v-if="store.state.supers.indexOf(store.state.userrole) >= 0"
		>
			Seçimi Kırp
		</r-btn>
		<r-btn
			style="width: 100%"
			@click="saveOneImage"
			:disabled="aRects.length === 0 || isSaving"
			size="medium"
			bgcolor="#15880d"
		>
			Kaydet
		</r-btn>

		<div class="r-edit-img-draws-C">
			<div class="edit-draw-C"></div>
		</div>
	</r-flex>
	<div class="canvas-C">
		<canvas
			class="draw-canvas"
			id="imgcontent"
			width="1000"
			height="1412.857142857143"
		></canvas>
		<canvas
			class="draw-canvas"
			id="drwcontent"
			width="1000"
			height="1412.857142857143"
		></canvas>
		<canvas
			class="draw-canvas"
			id="tmpcontent"
			width="1000"
			height="1412.857142857143"
			style="cursor: auto"
			@mousedown="mDown"
			@mouseup="mUp"
			@mousemove="mMove"
			@mouseout="mOut"
			@touchstart="mDown"
			@touchend="mUp"
			@touchmove="mMove"
		></canvas>
		<canvas
			class="draw-canvas"
			id="savecontent"
			width="1000"
			height="1412.857142857143"
		></canvas>
	</div>
</template>
<script>
	import { computed, onMounted, ref, watch } from 'vue'
	import { useStore } from 'vuex'
	export default {
		props: {
			img: String,
			data: Object,
			close: Boolean,
			saveme: {
				type: Boolean,
				default: false,
			},
		},
		setup(props, { emit }) {
			const store = useStore()
			const dataIn = ref(props.data)
			const cOffset = ref({})
			const aRects = ref([])
			const saveImageDatas = ref([])
			const showsave = ref(false)
			const readyImages = ref({})
			const imageIntervals = ref({})
			const canvases = ref({})
			const canvasesImages = ref({})
			const imgwidth = ref(null)
			const imgheight = ref(null)
			const isSaving = ref(false)
			const isLoading = ref(false)
			const imgContainer = ref()
			const rectCount = ref(0)
			const editMode = ref(false)

			var imgc, ictx, drwc, dctx, tmpc, tctx, savc, sctx
			var startX, startY, mouseX, mouseY
			var isDown = false
			var issave = false
			var maxselectbox = 3
			var swidth, sheight

			var txtout = false
			var tbackpos
			var ischrome = /chrome/.test(navigator.userAgent.toLowerCase())
			var rr = 1
			var fnt1 = 15
			var fnt2 = 17.8
			var maxselecth = 10
			var maxselectw = 20
			var imgrect
			var rect = null

			const dateNow = computed(() => {
				let dt = new Date()
				return (
					dt.getDate().toString().padStart(2, '0') +
					(dt.getMonth() + 1).toString().padStart(2, '0') +
					dt.getFullYear() +
					'_' +
					dt.getHours().toString().padStart(2, '0') +
					dt.getMinutes().toString().padStart(2, '0') +
					dt.getSeconds().toString().padStart(2, '0')
				)
			})

			const startDraw = () => {
				isLoading.value = true
				readyImages.value = {}
				imageIntervals.value = {}
				imgc = document.querySelector('#imgcontent')
				drwc = document.querySelector('#drwcontent')
				tmpc = document.querySelector('#tmpcontent')
				savc = document.querySelector('#savecontent')
				ictx = imgc.getContext('2d')
				dctx = drwc.getContext('2d')
				tctx = tmpc.getContext('2d')
				sctx = savc.getContext('2d')

				imgContainer.value = document.getElementsByClassName(
					'r-panel-content-C'
				)[0]

				swidth = imgc.width
				sheight = imgc.height
				imgrect = imgc.getBoundingClientRect()
				if (props.data.detail.length > 0) {
					editMode.value = true
					props.data.detail.forEach(itm => {
						let arr = itm.coordinates.split(';')
						rect = new dRect()
						rect.x = arr[0]
						rect.y = arr[1]
						rect.w = arr[2]
						rect.h = arr[3]
						rect.txt =
							props.data.subcatname + ' ' + props.data.examnumber + '. soru'
						rect.txtdesc =
							props.data.notename +
							' ' +
							props.data.fassiculeId +
							'. Fasikül Sayfa ' +
							props.data.pageNo.toString().padStart(3, '0')
						finishRect()
					})
				}
				editMode.value = false

				cOffset.value.left = imgrect.left
				cOffset.value.top = imgrect.top
				addImage(ictx, rr)
				isLoading.value = false
			}
			const undoF = () => {
				if (aRects.value.length > 0) {
					aRects.value.pop()
					if (
						dataIn.value.detail[dataIn.value.detail.length - 1].coordinates !==
						'0;0;0;0'
					) {
						dataIn.value.detail.pop()
					} else {
						dataIn.value.detail.splice(dataIn.value.detail.length - 2, 1)
					}
					saveImageDatas.value.pop()
					clear(dctx)
					rectCount.value--
					createAllRect(dctx, rr)
				}
			}
			const clearF = () => {
				aRects.value = []
				saveImageDatas.value = []
				dataIn.value.detail = []

				clear(dctx)
				rectCount.value = 0
			}
			const cancelF = () => {
				emit('update:close', false)
			}
			const addImage = (ctx, r) => {
				var img = new Image()
				img.src = props.img

				img.onload = async function () {
					imgwidth.value = this.width
					imgheight.value = this.height
					ctx.drawImage(img, 0, 0, swidth * r, sheight * r)
				}
			}
			const saveImages = () => {
				isSaving.value = true
				rr = imgwidth.value / 1000
				addImages(sctx, rr)
			}
			const saveOneImage = () => {
				isSaving.value = true
				rr = imgwidth.value / 1000

				var img = new Image()
				img.crossOrigin = 'anonymous'
				img.src = props.img
				img.onload = async function () {
					sctx.clearRect(0, 0, imgwidth.value, imgheight.value)
					sctx.fillStyle = '#ffffff'
					savc.width = imgwidth.value * rr
					savc.height = imgheight.value * rr
					sctx.drawImage(img, 0, 0, swidth * rr, sheight * rr)
					createAllRect(sctx, rr)
					var canv = document.createElement('canvas')
					canv.width = swidth * rr
					canv.height = sheight * rr
					var newimg = sctx.getImageData(0, 0, swidth * rr, sheight * rr)
					var newctx = canv.getContext('2d')
					newctx.putImageData(newimg, 0, 0)
					var b64img = await canv
						.toDataURL('image/jpeg', 0.9)
						.replace('data:image/jpeg;base64,', '')

					var imgint = setInterval(async () => {
						if (b64img !== '') {
							clearInterval(imgint)
							saveImageDatas.value.push({
								ImageString: b64img,
								Location:
									'referansnotlari/' +
									store.state.workFolder +
									'/edited_ref_img_' +
									store.state.usertype +
									'/' +
									props.data.catid +
									'/' +
									dataIn.value.noteid +
									'/' +
									dataIn.value.noteid +
									'_' +
									dataIn.value.pageNo +
									'_' +
									dateNow.value +
									'.jpg',
								isImg: true,
							})
							let res = await store.dispatch('postDatas', {
								api: 'references/saveimages',
								data: saveImageDatas.value,
							})
							dataIn.value.detail.forEach(det => {
								det.isImg = false
							})
							dataIn.value.detail.forEach((det, i) => {
								if (det.coordinates === '0;0;0;0')
									dataIn.value.detail.splice(i, 1)
							})
							dataIn.value.detail.push({
								coordinates: '0;0;0;0',
								note: '',
								referenceId: props.data.refid,
								fileName:
									dataIn.value.noteid +
									'_' +
									dataIn.value.pageNo +
									'_' +
									dateNow.value,
								isImg: true,
							})
							if (res.result) {
								dataIn.value.fileName =
									dataIn.value.noteid +
									'_' +
									dataIn.value.pageNo +
									'_' +
									dateNow.value
								dataIn.value.originalUrl = ''
								dataIn.value.url = ''
								emit('update:close', false)
								emit('update:data', dataIn.value)
								emit('update:saveme', true)
								readyImages.value = {}
								canvasesImages.value = {}
								canvases.value = {}
								imageIntervals.value = {}
							}
						}
					})
				}
			}

			const addImages = async (ctx, r) => {
				var img = new Image()
				img.crossOrigin = 'anonymous'
				img.src = props.img
				img.onload = async function () {
					sctx.fillStyle = '#ffffff'
					savc.width = imgwidth.value * rr
					savc.height = imgheight.value * rr
					sctx.drawImage(img, 0, 0, swidth * r, sheight * r)
					var intii = 0
					var saveok = true
					var loopInt = setInterval(async () => {
						if (saveok) {
							saveok = false
							sctx.clearRect(0, 0, imgwidth.value, imgheight.value)
							sctx.drawImage(img, 0, 0, swidth * r, sheight * r)
							sctx.fillStyle = '#ffffff'
							var itm = aRects.value[intii]
							await itm.createSaveRect(sctx, r)
							let topval = parseInt(itm.y) * r - parseInt(itm.titleh) - 20 * r
							if (topval < 0) topval = 0
							let botval = parseInt(itm.titleh) + parseInt(itm.h) * r + 40 * r
							if (topval + botval > sheight * r)
								botval = sheight * r - topval - 2 * r

							canvases.value['c' + itm.ii] = document.createElement('canvas')
							canvases.value['c' + itm.ii].width = swidth * r
							canvases.value['c' + itm.ii].height = botval
							canvases.value['c' + itm.ii].id = 'canid' + itm.ii

							var newimg = sctx.getImageData(0, topval, imgwidth.value, botval)

							canvasesImages['ci' + itm.ii] = canvases.value[
								'c' + itm.ii
							].getContext('2d')
							canvasesImages['ci' + itm.ii].fillStyle = '#ffffff'
							canvasesImages['ci' + itm.ii].putImageData(newimg, 0, 0)

							readyImages.value['v' + itm.ii] = await canvases.value['c' + itm.ii]
								.toDataURL('image/jpeg', 0.9)
								.replace('data:image/jpeg;base64,', '')

							imageIntervals.value['i' + itm.ii] = setInterval(() => {
								if (readyImages.value['v' + itm.ii] !== '') {
									clearInterval(imageIntervals.value['i' + itm.ii])
									delete imageIntervals.value['i' + itm.ii]

									saveImageDatas.value.push({
										ImageString: readyImages.value['v' + itm.ii],
										Location:
											'referansnotlari/' +
											store.state.workFolder +
											'/edited_ref_img_' +
											store.state.usertype +
											'/' +
											props.data.catid +
											'/' +
											dataIn.value.noteid +
											'/' +
											dataIn.value.noteid +
											'_' +
											dataIn.value.pageNo +
											'_' +
											dateNow.value +
											'_' +
											(parseInt(itm.ii) + 1) +
											'.jpg',
									})
									if (itm.ii === aRects.value.length - 1) {
										clearInterval(loopInt)
									} else {
										saveok = true
									}
									intii++
								}
							}, 100)
						}
					}, 200)

					let intv = setInterval(async () => {
						if (saveImageDatas.value.length === aRects.value.length) {
							clearInterval(intv)

							let res = await store.dispatch('postDatas', {
								api: 'references/saveimages',
								data: saveImageDatas.value,
							})
							if (res.result) {
								for (let i = dataIn.value.detail.length - 1; i > 0; i--) {
									if (dataIn.value.detail[i].coordinates === '0;0;0;0')
										dataIn.value.detail.splice(i, 1)
								}
								dataIn.value.detail.forEach((det, i) => {
									if (det.coordinates !== '0;0;0;0') {
										det.fileName = saveImageDatas.value[i].Location.replace(
											'referansnotlari/' +
												store.state.workFolder +
												'/edited_ref_img_' +
												store.state.usertype +
												'/' +
												props.data.catid +
												'/' +
												dataIn.value.noteid +
												'/',
											''
										).replace('.jpg', '')
										det.isImg = true
									}
								})
								dataIn.value.detail.forEach((det, i) => {
									if (det.coordinates === '0;0;0;0')
										dataIn.value.detail.splice(i, 1)
								})
								dataIn.value.fileName =
									dataIn.value.noteid +
									'_' +
									dataIn.value.pageNo +
									'_' +
									dateNow.value
								dataIn.value.originalUrl = ''
								dataIn.value.url = ''
								emit('update:close', false)
								emit('update:data', dataIn.value)
								emit('update:saveme', true)
								readyImages.value = {}
								canvasesImages.value = {}
								canvases.value = {}
								imageIntervals.value = {}
							}
						}
					}, 250)
				}
			}
			function dRect() {
				this.x = 0
				this.y = 0
				this.w = 1
				this.h = 1
				this.toth = 1
				this.totw = 1
				this.titleh = 1
				this.titley = 1
				this.ii = 0
				this.rsp = 8
				this.txt = props.data.subcatname + ' ' + props.data.examnumber + '. soru'
				this.txtdesc =
					props.data.notename +
					' ' +
					props.data.fassiculeId +
					'. Fasikül Sayfa ' +
					props.data.pageNo.toString().padStart(3, '0')
			}
			dRect.prototype = {
				createRect: function (ctx, r) {
					ctx.lineWidth = 3
					ctx.strokeStyle = 'red'
					ctx.clearRect(0, 0, swidth, sheight)
					ctx.strokeRect(this.x * r, this.y * r, this.w * r, this.h * r)
					this.createText(ctx, r)
				},
				finishRect: function (ctx, r) {
					ctx.lineWidth = 4
					ctx.strokeStyle = '#2b1869'
					ctx.strokeRect(this.x * r, this.y * r, this.w * r, this.h * r)
					this.createText(ctx, r)
				},
				createSaveRect: function (ctx, r) {
					ctx.lineWidth = 4 * r
					ctx.strokeStyle = '#2b1869'
					ctx.strokeRect(this.x * r, this.y * r, this.w * r, this.h * r)
					this.createText(ctx, r, true)
				},
				createText: async function (ctx, r, s) {
					ctx.font = fnt1 * r + 'pt Arial'
					var th = parseInt(ctx.font)
					var tw = ctx.measureText(this.txt).width
					var backh
					if (tw < 350 * r) {
						tw = 350 * r
					}
					var mw = Math.abs(this.w * r)
					if (Math.abs(this.w) < 350 * r) {
						mw = 350 * r
					}
					var lines = wrapText(ctx, this.txtdesc, mw)
					if (ischrome) {
						backh = lines.length * th
					} else {
						if (!issave) {
							backh = lines.length * (th + 10)
						} else {
							backh = lines.length * (th + 6)
						}
					}
					ctx.font = fnt2 * r + 'pt Arial'
					ctx.font = 'bold'
					th = parseInt(ctx.font)
					backh = backh + th
					this.titleh = backh
					this.createTextBack(ctx, backh, tw, r, s)
					ctx.fillStyle = '#2C2257'
					var yy, xx

					yy = this.titley + 30 * r
					if (!txtout) {
						if (this.w * r < 0) {
							xx = this.x * r + this.w * r + 10
						} else {
							xx = this.x * r + 10
						}
					} else {
						xx = tbackpos + 10
					}

					if (this.y * r - th - 10 > 0) {
						ctx.fillText(this.txt, xx, yy - 5)
					} else {
						ctx.fillText(this.txt, xx, yy - 5)
					}

					ctx.font = fnt1 * r + 'pt Arial'
					ctx.fillStyle = '#2C2257'
					th = parseInt(ctx.font)

					lines.forEach(function (line, i) {
						let yp = yy + (th - 2 * r) * (i + 1)
						if (i > 0) yp = yy + (th - 2 * r) * (i + 1) + 4
						if (ischrome) {
							ctx.fillText(line, xx, yp)
						} else {
							if (i === 0) ctx.fillText(line, xx, yp + 5)
							else ctx.fillText(line, xx, yp + 9)
						}
					})
				},
				createTextBack: function (ctx, th, tw, r, s) {
					ctx.beginPath()
					var ww, yy, xx
					var oldh = 0

					if (this.ii == 1 && !s) {
						oldh = aRects.value[0].y * r + aRects.value[0].h * r
					}

					if (this.y * r - th - 15 > 0) {
						if (this.h * r < 0) {
							yy = this.y * r + this.h * r - th - 15
						} else {
							yy = this.y * r - th - 15
						}
						if (this.ii > 0) {
							if (parseInt(this.y * r - th - 15) < oldh) {
								yy = this.y * r + this.h * r
							}
						}
					} else {
						if (this.y + this.h + th + 20 > sheight) {
							yy = this.y * r + this.h * r - (this.y + this.h + th + 20 - sheight)
						} else {
							yy = this.y * r + this.h * r
						}
					}
					this.titley = yy

					if (this.w * r < 0) {
						xx = this.x * r + this.w * r
					} else {
						xx = this.x * r
					}
					if (Math.abs(this.w * r) > tw + 20) {
						ww = Math.abs(this.w * r)
					} else {
						ww = tw + 20
					}

					if (xx + ww > swidth * r) {
						txtout = true
						xx = swidth * r - ww
						tbackpos = xx
					} else {
						txtout = false
					}
					ctx.rect(xx, yy, ww, th + 15 * r)
					ctx.fillStyle = '#fffdc2'
					ctx.fill()
					ctx.strokeRect(xx, yy, ww, th + 15 * r)
					this.toth = th + 15
					this.totw = ww
				},
			}
			const wrapText = (ctx, txt, mw) => {
				var words = txt.split(' '),
					lines = [],
					line = ''
				if (ctx.measureText(txt).width < mw) {
					return [txt]
				}
				while (words.length > 0) {
					while (ctx.measureText(words[0]).width >= mw) {
						var tmp = words[0]
						words[0] = tmp.slice(0, -1)
						if (words.length > 1) {
							words[1] = tmp.slice(-1) + words[1]
						} else {
							words.push(tmp.slice(-1))
						}
					}
					if (ctx.measureText(line + words[0]).width + 15 < mw) {
						line += words.shift() + ' '
					} else {
						lines.push(line)
						line = ''
					}
					if (words.length === 0) {
						lines.push(line)
					}
				}
				return lines
			}
			const mDown = e => {
				rect = new dRect()
				var p = swidth / imgc.clientWidth
				startX = e.layerX * p
				startY = e.layerY * p
				if (rectCount.value < maxselectbox) {
					isDown = true
				} else {
					console.log('En fazla üç seçim yapabilirsiniz', 'alert')
				}
			}

			const mUp = () => {
				if (!isDown) {
					return
				}
				isDown = false
				finishRect()
				dataIn.value.detail.push({
					coordinates: rect.x + ';' + rect.y + ';' + rect.w + ';' + rect.h + ';',
					note: '',
					referenceId: props.data.refid,
					fileName:
						dataIn.value.noteid +
						'_' +
						dataIn.value.pageNo +
						'_' +
						dateNow.value +
						'_' +
						(parseInt(dataIn.value.detail.length) + 1),
				})
			}
			const mOut = () => {
				if (!isDown) {
					return
				}
				isDown = false
				finishRect()
				dataIn.value.detail.push({
					coordinates: rect.x + ';' + rect.y + ';' + rect.w + ';' + rect.h + ';',
					note: '',
					referenceId: props.data.refid,
					fileName:
						dataIn.value.noteid +
						'_' +
						dataIn.value.pageNo +
						'_' +
						dateNow.value +
						'_' +
						(parseInt(dataIn.value.detail.length) + 1),
				})
			}
			const mMove = e => {
				if (!isDown) {
					return
				}
				var p = swidth / imgc.clientWidth
				mouseX = parseInt(e.layerX * p)
				mouseY = parseInt(e.layerY * p)
				createRect()
			}
			const clear = c => {
				c.clearRect(0, 0, swidth, sheight)
			}
			const finishRect = () => {
				if (editMode.value) {
					if (
						rect.x !== '0' &&
						rect.y !== '0' &&
						rect.w !== '0' &&
						rect.h !== '0'
					) {
						rect.ii = rectCount.value
						aRects.value.push(rect)
						rect.finishRect(dctx, rr)
						rectCount.value++
						clear(tctx)
					}
				} else {
					if (
						Math.abs(mouseX - startX) > maxselectw &&
						Math.abs(mouseY - startY) > maxselecth
					) {
						if (rect.x !== 0 && rect.y !== 0 && rect.w !== 0 && rect.h !== 0) {
							rect.ii = rectCount.value
							aRects.value.push(rect)
							rect.finishRect(dctx, rr)
							rectCount.value++
							clear(tctx)
						}
					}
				}
			}
			const createRect = () => {
				if (
					Math.abs(mouseX - startX) > maxselectw &&
					Math.abs(mouseY - startY) > maxselecth
				) {
					if (mouseY < startY) {
						if (mouseX > startX) {
							rect.x = startX
							rect.w = mouseX - startX
						} else {
							rect.x = mouseX
							rect.w = startX - mouseX
						}
						rect.y = mouseY
						rect.h = startY - mouseY
					} else {
						if (mouseX > startX) {
							rect.x = startX
							rect.w = mouseX - startX
						} else {
							rect.x = mouseX
							rect.w = startX - mouseX
						}
						rect.y = startY
						rect.h = mouseY - startY
					}
					if (rect.y + rect.h + rect.toth > sheight && rect.titley >= rect.y) {
						rect.h = sheight - rect.y - rect.toth
					}
					rect.createRect(tctx, rr)
				}
			}
			const createAllRect = (ctx, r) => {
				aRects.value.forEach(rec => {
					if (rec.x !== 0 && rec.y !== 0 && rec.w !== 0 && rec.h !== 0)
						rec.createSaveRect(ctx, r)
				})
			}
			watch(
				() => props.img,
				() => {
					startDraw()
					aRects.value = []
					rectCount.value = 0
					clear(tctx)
					clear(dctx)
				}
			)
			onMounted(() => {
				startDraw()
			})
			return {
				startDraw,
				undoF,
				clearF,
				cancelF,
				saveImages,
				saveOneImage,
				mDown,
				mUp,
				mMove,
				mOut,
				aRects,
				showsave,
				isSaving,
				isLoading,
				store,
			}
		},
	}
</script>
<style>
	.canvas-C {
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}
	.draw-canvas {
		position: absolute;
		height: 100%;
		widows: auto;
	}
	#imgcontent {
		z-index: 1;
	}
	#drwcontent {
		z-index: 2;
	}
	#tmpcontent {
		z-index: 3;
	}
	#savecontent {
		display: none;
	}
	.r-edit-img-button-C {
		position: absolute;
		top: calc(48% - 50px);
		z-index: 9;
		left: 100%;
		flex-direction: column;
		align-items: flex-end;
		justify-content: flex-end;
		flex: 1 1 auto;
		width: 100px;
		background-color: #fff;
		padding: 4px;
		border-radius: 0 4px 4px 0;
	}
</style>
